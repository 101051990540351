
import {Save,Obtener,Editar} from '@/Generico/Conexion';
import ResponseGenerico from '../ResponseGenerico';
import {mainAxios} from '@/plugins/axios'
class Aprobacion
{
    aprobacionId:number = 0;
    isSave:boolean = true;
    zonaId:number =0;
    zonaName:string ="";
    estado:boolean = true;
    aprobacionDetalles: Array<AprobacionDetalle> =[];
}

class AprobacionDetalle
{
    aprobacionId:number =0;
    aprobacionDetalleId:number =0;
    usuarioId:string ="";
    usuarioName:string ="";
    nivel:number =0;
}


async function  GuardarAprobacion(Aprobacion:Aprobacion):Promise<ResponseGenerico>
{
    return await Save<Aprobacion>("Aprobacion/Guardar",Aprobacion,mainAxios);
} 

async function  GuardarAprobacionDetalle(detalle:AprobacionDetalle):Promise<ResponseGenerico>
{
    return await Save<AprobacionDetalle>("Aprobacion/GuardarAprobacionDetalle",detalle,mainAxios);
} 


async function ObtenerAprobacion()
{
    return await Obtener<Aprobacion>("Aprobacion/Obtener",mainAxios);
}


async function  EditarEstadoAprobacion(aprobacion:Aprobacion):Promise<ResponseGenerico>
{
    return await Editar<Aprobacion>("Aprobacion/EditarEstado",aprobacion,mainAxios);
}
export 
{
    Aprobacion,AprobacionDetalle,
    GuardarAprobacion,GuardarAprobacionDetalle,
    ObtenerAprobacion,EditarEstadoAprobacion

}