




































































import ZonaGreogrfica from '@/entidades/Plantacion/ZonaGeografica';
import TablaDelete from '@/components/General/TablaDelete.vue';
import Usuario2 from '@/entidades/Maestro/Usuario2';
import Usuario from '@/entidades/Maestro/Usuario';
import {Vue,Prop,Component} from 'vue-property-decorator';
import { Aprobacion, AprobacionDetalle, GuardarAprobacion } from '@/entidades/Plantacion/Aprobacion';
import AprobacionView from '@/views/Plantacion/Aprobacion.vue';
import {Action} from 'vuex-class';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
@Component({
    components:{
        TablaDelete
    }
})
export default class Formulario extends Vue
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function

    @Prop({type:Object ,required:false}) edicionItem!:Aprobacion;
    @Prop({type:Boolean ,required:true,default:true}) isSave!:boolean;
    listadoZona:Array<ZonaGreogrfica> =[];
    listadoUsuario:Array<Usuario2> =[];
    zonaId:number=0;
    usuarioId:string ="";
    header:{text:string,sortable:boolean,value:string}[] =[
            {text:"Usuario",sortable:true,value:'usuario'},
            {text:'Nivel', sortable:true, value: 'nivel'},
            {text:'Eliminar', sortable:true, value: 'eliminar'},
    ]
    items:{usuario:string|undefined,nivel:number,usuarioId:string}[] =[];
    mensajeError:string ="";
    nivel:number=1;
    aprobacionId:number = 0;

    async ObtenerZona()
    {
        try
        {
            let response = await ZonaGreogrfica.Obtener();
            if(response.isSuccess == true)
                this.listadoZona = response.isResult;
        }
        catch(error)
        {
            console.log(error);
        }
    }

    async ObtenerUsuarioSistema()
    {
        try
        {
            let response = await Usuario.ObtenerUsuarioSistema(1);
            if(response.isSuccess == true)
                this.listadoUsuario = response.isResult;
        }
        catch(error)
        {
            console.log(error);
        }
    }

    agregar()
    {
        let rsp = this.items.find(c=>c.usuarioId == this.usuarioId);
        console.log(rsp);
        if(rsp != undefined || rsp != null)
        {
            this.changeAlerta(new Alerta("El usuario ya se encuentra registrado",true,"danger",1000));
            return;
           
        }
        else
        {
            rsp = this.items.find(c=>c.nivel== this.nivel);
            if(rsp != undefined || rsp != null)
            {
                this.changeAlerta(new Alerta("El nivel seleccionado ya se encuentra registrado",true,"danger",1000));
                return;
            }
            else
            {
                let usuarioName:string |undefined ="";
                usuarioName = this.listadoUsuario.find(c=>c.usuarioId == this.usuarioId)?.usuarioName;
                this.items.push({usuarioId:this.usuarioId,nivel:this.nivel,usuario:usuarioName});
            }
               
        }

       
    }

    eliminarItem(item:any)
    {
       this.items.splice(this.items.indexOf(item),1);
    }

    mounted() {
        this.ObtenerZona();   
        this.ObtenerUsuarioSistema();
        if(this.edicionItem != undefined)
        {
            this.edicionItem.aprobacionDetalles.forEach(t=>{
                this.items.push({usuarioId:t.usuarioId,nivel:t.nivel,usuario:t.usuarioName});
            })
            this.zonaId = this.edicionItem.zonaId;
            this.aprobacionId = this.edicionItem.aprobacionId;
        }
    }

    async guardar()
    {
        try
        {
            this.changeLoading(new Loading(true,"Registrando informacion"));
            if(this.items.length > 0)
            {
            //let detalle = new AprobacionDetalle();
            let listado:Array<AprobacionDetalle> = [];
            let aprob = new Aprobacion();
            aprob.zonaId = this.zonaId;
            aprob.estado  = true;
            aprob.isSave = this.isSave;
            aprob.aprobacionId = this.aprobacionId;
            this.items.forEach((element => {
              let detalle = new AprobacionDetalle();
               detalle.usuarioId = element.usuarioId;
               detalle.nivel = element.nivel;
              listado.push(detalle);

           }))
            aprob.aprobacionDetalles = listado;
            console.log(aprob);
            let response = await GuardarAprobacion(aprob);
            if(response.isSuccess == true)
            {
                this.$emit('limpiar');
            }
            else
            {
                this.changeAlerta(new Alerta(response.isMessage,true,"danger",1000));
            }
            }
        }
        catch(error)
        {
            //@ts-ignore
            this.changeAlerta(new Alerta(error,true,"danger",1000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
}
