









































import {Vue,Component} from 'vue-property-decorator';
import {Aprobacion, ObtenerAprobacion} from '@/entidades/Plantacion/Aprobacion';
import TablaGeneral from '@/components/General/TablaGeneral.vue';
import Formulario from '@/components/Aprobacion/Formulario.vue';
import {Action} from 'vuex-class'
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
@Component({
    components:{
        TablaGeneral,
        Formulario
    }
})
export default class AprobacionView extends Vue
{
    //VUEX
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function

     //Propiedas y atributos
    header:{text:string,sortable:boolean,value:string}[] =[
            {text:"Zona",sortable:true,value:'zonaName'},
            {text:"Editar",sortable:false,value:'actions'},
            
    ]
    listado:Array<Aprobacion> =[];
    search:string ="";
    modalAgregar:boolean=false;
    currentComponent:string="";
    aprobacionSeleccionada:Aprobacion | undefined = new Aprobacion();
    isSave:boolean = true;
    abrilModal()
    {
        this.isSave = true;
        this.currentComponent = "Formulario";
        this.aprobacionSeleccionada = undefined;
        this.modalAgregar=true;
    }
    limpiar()
    {
        this.isSave = true;
        this.modalAgregar = false;
        this.currentComponent = "";
        this.Obtener();
    };

    async Obtener()
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion"));
            let response = await ObtenerAprobacion();
            if(response.isSuccess == true)
            {
                     this.listado = response.isResult;
            }
            else
            {
               this.changeAlerta(new Alerta(response.isMessage,true,"danger",1000));
            
            }
        }
        catch(error)
        {
            console.log(error);
        }
        finally
        {
             this.changeLoading(new Loading(false,""));
        }
    }
    editarAsignacion(item:Aprobacion)
    {
        this.aprobacionSeleccionada = item;
        this.isSave = false;
        this.currentComponent = "Formulario";
        this.modalAgregar=true;
    }
    mounted() {
        this.Obtener();   
    }
}
